import { defineAsyncComponent } from 'vue'
// import Header from './header/Main.vue'
// import Footer from './footer/Main.vue'
// import Notification from './notification/Main.vue'
// import Chart from './chart/Main.vue'
// import GoogleMapLoader from './google-map-loader/Main.vue'
// import Highlight from './highlight/Main.vue'
// import Litepicker from './litepicker/Main.vue'
import Tippy from './tippy/Main.vue'
// import TippyContent from './tippy-content/Main.vue'
import TomSelect from './tom-select/Main.vue'
// import LoadingIcon from './loading-icon/Main.vue'
// import TinySlider from './tiny-slider/Main.vue'
// import ClassicEditor from './ckeditor/ClassicEditor.vue'
// import BalloonBlockEditor from './ckeditor/BalloonBlockEditor.vue'
// import BalloonEditor from './ckeditor/BalloonEditor.vue'
// import DocumentEditor from './ckeditor/DocumentEditor.vue'
// import InlineEditor from './ckeditor/InlineEditor.vue'
import Dropzone from './dropzone/Main.vue'
// import FullCalendar from './calendar/Main.vue'
// import FullCalendarDraggable from './calendar/Draggable.vue'
import * as featherIcons from '@zhuowenli/vue-feather-icons'
// import { DollarSignIcon } from '@zhuowenli/vue-feather-icons'

// const GlobeIcon = defineAsyncComponent(() =>
//   import('@zhuowenli/vue-feather-icons/icons/GlobeIcon')
// )
// const HomeIcon = defineAsyncComponent(() =>
//   import('@zhuowenli/vue-feather-icons/icons/HomeIcon')
// )
// const UsersIcon = defineAsyncComponent(() =>
//   import('@zhuowenli/vue-feather-icons/icons/UsersIcon')
// )
// const UserIcon = defineAsyncComponent(() =>
//   import('@zhuowenli/vue-feather-icons/icons/UserIcon')
// )
const Chart = defineAsyncComponent(() =>
  import(/* webpackChunkName: "StartCharts" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ /* webpackPreload: true */'./chart/Main.vue')
)
const LoadingIcon = defineAsyncComponent(() =>
  import(/* webpackChunkName: "LoadingIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ /* webpackPreload: true */'./loading-icon/Main.vue')
)
const Notification = defineAsyncComponent(() =>
  import(/* webpackChunkName: "Notification" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ /* webpackPreload: true */'./notification/Main.vue')
)
const Header = defineAsyncComponent(() =>
  import('./header/Main.vue')
)
const Litepicker = defineAsyncComponent(() =>
  import(/* webpackChunkName: "LightPicker" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ /* webpackPreload: true */'./litepicker/Main.vue')
)
// const Dropzone = defineAsyncComponent(() =>
//   import('./dropzone/Main.vue')
// )

export default app => {
  app.component('Header', Header)
  // app.component('Footer', Footer)
  app.component('Notification', Notification)
  app.component('Chart', Chart)
  // app.component('GoogleMapLoader', GoogleMapLoader)
  // app.component('Highlight', Highlight)
  app.component('Litepicker', Litepicker)
  app.component('Tippy', Tippy)
  // app.component('TippyContent', TippyContent)
  app.component('TomSelect', TomSelect)
  app.component('LoadingIcon', LoadingIcon)
  // app.component('TinySlider', TinySlider)
  app.component('Dropzone', Dropzone)
  // app.component('GlobeIcon', GlobeIcon)
  // app.component('HomeIcon', HomeIcon)
  // app.component('UsersIcon', UsersIcon)
  // app.component('UserIcon', UserIcon)
  // app.component('featherIcons', featherIcons)
  // app.component('ClassicEditor', ClassicEditor)
  // app.component('BalloonBlockEditor', BalloonBlockEditor)
  // app.component('BalloonEditor', BalloonEditor)
  // app.component('DocumentEditor', DocumentEditor)
  // app.component('InlineEditor', InlineEditor)
  // app.component('FullCalendar', FullCalendar)
  // app.component('FullCalendarDraggable', FullCalendarDraggable)

  for (const [key, icon] of Object.entries(featherIcons)) {
    icon.props.size.default = '24'
    app.component(key, icon)
  }
}
