/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:effbd9ce-550f-4e9b-97d3-b47a6bf50118",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_c1bo6qHIs",
    "aws_user_pools_web_client_id": "1gfq4cnrcpdh1an3q36bkpe2r2",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "prebidstats-20221222145017-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://dg4hqtr2xe2de.cloudfront.net",
    "aws_cognito_login_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
