<template>
  <span id="app1">
    <router-view></router-view>
    <vue-progress-bar></vue-progress-bar>
    <div
        ref="scrollTopButton"
        class="invisible sticky w-full flex justify-end bottom-20 pb-3 pr-2 lg:pr-2 transition"
    >
      <div @click="scrollToTop" class="w-8 h-8 bg-theme-8 text-theme-4 hover:text-blue-400 transition flex items-center justify-center rounded-full">
        <button  role="button" aria-label="scroll to top of the page">
          <ArrowUpIcon class="block mx-auto" />
        </button>
      </div>
    </div>
  </span>
  <span class="intro-y w-full flex justify-center relative pb-10 -mt-8 ml-auto mr-auto">
      <p class="ml-2">© 2023 Adysis.</p>
      <small class="ml-2" >| </small>
      <p class="ml-2">V 1.1.0.0</p>
      <small class="ml-2" >| </small>
      <a class="ml-2" href="/">Home</a>
      <small class="ml-2" >| </small>
      <a class="ml-2" href="/termsandconditions">Terms and Conditions</a>
      <small class="ml-2" >| </small>
      <a class="ml-2" href="/privacypolicy">Privacy Policy</a>
      <small class="ml-2" >| </small>
      <a class="ml-2" href="/optout">Opt-out</a>
      <small class="ml-2" >| </small>
      <span class="qc-demo-page-footer" id="qc-demo-page-footer" ></span>
      <!-- <label class="ml-2" >| </label>
      <a class="ml-2 mr-auto" href="/help">Help</a> -->
    </span>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
  },
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
    handleScroll() {
      const scrollBtn = this.$refs.scrollTopButton

      if (window.scrollY > 0) {
        scrollBtn.classList.remove('invisible')
      } else {
        scrollBtn.classList.add('invisible')
      }
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },

  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }
})
</script>
