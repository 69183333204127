const initialState = [
  {
    icon: 'HomeIcon',
    pageName: 'home',
    title: 'Dashboard'
  },
  {
    icon: 'BarChart2Icon',
    pageName: '',
    title: 'Reports',
    subMenu: [
      {
        icon: 'VideoIcon',
        pageName: 'videoStats',
        title: 'Video Stats'
      },
      {
        icon: 'MonitorIcon',
        pageName: 'displayStats',
        title: 'Display Stats'
      }
    ]
  },
  'devider',
  {
    icon: 'UserIcon',
    pageName: 'side-menu-profile',
    title: 'Profile'
  },
  {
    icon: 'FileIcon',
    pageName: 'side-menu-billing',
    title: 'Billing'
  },
  {
    icon: 'DollarSignIcon',
    pageName: 'side-menu-payments',
    title: 'Payments'
  },
  {
    icon: 'TargetIcon',
    pageName: 'side-menu-adstxt',
    title: 'Ads.txt'
  },
  {
    icon: 'ClockIcon',
    pageName: 'side-menu-bidder-status',
    title: 'Bidder Status'
  },
  'devider',
  {
    icon: 'ServerIcon',
    pageName: 'side-menu-widgets',
    title: 'System Status',
    // access: 'pub',
    subMenu: [
      // {
      //   icon: 'ClockIcon',
      //   pageName: 'side-menu-bidder-status',
      //   title: 'Bidder Status'
      // },
      {
        icon: 'GlobeIcon',
        pageName: 'side-menu-status',
        title: 'Global Status'
      },
      {
        icon: 'BookOpenIcon',
        pageName: 'side-menu-help',
        title: 'Help Centre'
      }
    ]
  },
  // {
  //   icon: 'ClockIcon',
  //   pageName: 'bidder-status',
  //   title: 'Bidder Status'
  // },
  'devider',
  {
    icon: 'SettingsIcon',
    pageName: 'side-menu-widgets',
    title: 'Admin Panel',
    access: 'admin',
    subMenu: [
      {
        icon: 'HexagonIcon',
        pageName: 'bidders',
        title: 'Bidders'
      },
      {
        icon: 'UsersIcon',
        pageName: 'users',
        title: 'Users'
      },
      {
        icon: 'LinkIcon',
        pageName: 'sites',
        title: 'Sites'
      },
      {
        icon: 'InboxIcon',
        pageName: 'accounting',
        title: 'Accounting'
      },
      {
        icon: 'TrelloIcon',
        pageName: 'options',
        title: 'Options'
      },
      {
        icon: 'MessageSquareIcon',
        pageName: 'notifications',
        title: 'Notifications'
      }
      // {
      //   icon: 'ClockIcon',
      //   pageName: 'bidder-status',
      //   title: 'Bidder Status'
      // },
      // {
      //   icon: 'GlobeIcon',
      //   pageName: 'status',
      //   title: 'Global Status'
      // }
    ]
  }
]

const state = () => {
  return {
    menu: initialState,
    defaultMenu: JSON.parse(JSON.stringify(initialState)),
    statsStatus: {
      video: true,
      display: true
    }
  }
}

// getters
const getters = {
  menu: state => state.menu
}

// actions
const actions = {}

// mutations
const mutations = {
  SET_MENU (state, payload) {
    state.menu = payload
  },
  SET_STATS_STATUS (state, payload) {
    state.statsStatus = payload
  },
  RESET_MENU (state) {
    state.menu = initialState
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
