import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import globalComponents from './global-components'
// import utils from './utils'
// import './libs'
import axios from 'axios'
import VueProgressBar from '@aacassandra/vue3-progressbar'
// import VueTelInput from 'vue-tel-input'
// import 'vue-tel-input/dist/vue-tel-input.css'
// import VueCryptojs from 'vue-cryptojs'
// import Amplify from 'aws-amplify'
import Amplify from '@aws-amplify/core'

// import Auth from '@aws-amplify/auth'
import awsconfig from './aws-exports'

import './registerServiceWorker'
import InvoiceDownloadPrint from './utils/invoice-download-print'

// SASS Theme
import(/* webpackPreload: true */'./assets/sass/app.scss')

Amplify.configure(awsconfig)
// Amplify.configure; { Auth }

// const globalOptions = {
//   mode: 'international'
// }

const app = createApp(App)
  .use(store)
  .use(router)
  // .use(VueTelInput, globalOptions)
  // .use(VueCryptojs)
  .use(VueProgressBar, {
    color: '#bffaf3',
    failedColor: '#874b4b',
    thickness: '6px',
    transition: {
      speed: '0.2s',
      opacity: '0.4s',
      termination: 500
    },
    autoRevert: true,
    location: 'top',
    inverse: false,
    autoFinish: true
  })

const prebidAPIConfig = {
  aws_appsync_graphqlEndpoint: 'https://wotqygeodrcg7bhtaf67icmdo4.appsync-api.us-east-1.amazonaws.com/graphql',
  aws_appsync_region: 'us-east-1',
  aws_appsync_authenticationType: 'API_KEY',
  aws_appsync_apiKey: 'da2-bl4qacjpgjfqvkhhwcsrt5hk74'
}

Amplify.configure(prebidAPIConfig)

// app.config.globalProperties.apiUrlScan = 'https://ap7m5mvs4c.execute-api.us-east-1.amazonaws.com/scan/prebid-db-scan/'
app.config.globalProperties.apiUrlScan = 'https://j5tq62qetxxp64p55kpqrzruxu0rddyx.lambda-url.us-east-1.on.aws/'
app.config.globalProperties.apiUrlAdsTxt = 'https://f1n6zpbfu4.execute-api.us-east-1.amazonaws.com/adstxt/prebidstats-adstxt'

app.config.globalProperties.btApiKey = 'key_VfbeTQwMVHv5dASnKr4YBz'
app.config.globalProperties.btServerKey = 'key_Sehfwu5arUvH6xYMgtos1R'

app.config.globalProperties.globalPaymentStatusPaid = 'Paid'
app.config.globalProperties.globalPaymentStatusLocked = 'Locked'
app.config.globalProperties.globalPaymentStatusAvailable = 'Available'
app.config.globalProperties.globalPaymentStatusPending = 'Pending Payment'
app.config.globalProperties.globalPaymentStatusAmountLow = 'Deferred - Amount To Low'
app.config.globalProperties.globalPaymentStatusMissingSettings = 'Deferred - Missing Payment Settings'

app.config.globalProperties.globalPaymentTypeDirect = 'Direct Deposit'
app.config.globalProperties.globalPaymentTypeWired = 'Wire Transfer'
app.config.globalProperties.globalPaymentTypePaypal = 'Paypal'
app.config.globalProperties.globalPaymentTypePayoneer = 'Payoneer'

app.config.performance = true

globalComponents(app)
// utils(app)

app.mixin({
  methods: {
    pbPrepareParams: async function(params = {}) {
      params.type = 'scan'
      return await this.pbScanTable(params)
    },
    pbScanTable: async function(params) {
      let res = []
      await axios({
        method: 'post',
        url: this.apiUrlScan + new Date().getTime(),
        headers: {
          'Content-Type': 'text/plain'
        },
        data: JSON.stringify(params)
      })
        .then(function (response) {
          res = response.data.items
        })
        .catch(function (error) {
          res = error
        })

      return res
    },
    pbGetBidders: async function(params = {}) {
      params.table = 'prebid-db-bidders-list'
      return await this.pbPrepareParams(params)
    },
    pbGetSites: async function(params = {}) {
      params.table = 'prebid-db-sites'
      return await this.pbPrepareParams(params)
    },
    pbGetUsers: async function(params = {}) {
      params.table = 'prebid-db-users'
      return await this.pbPrepareParams(params)
    },
    pbGetBiddersSummaries: async function(params = {}) {
      params.table = 'prebid-db-bidders-summary'
      return await this.pbPrepareParams(params)
    },
    pbGetBiddersTotal: async function(params = {}) {
      params.table = 'prebid-db-bidders-totals'
      return await this.pbPrepareParams(params)
    },
    pbGetPayments: async function(params = {}) {
      params.table = 'prebid-db-payments'
      return await this.pbPrepareParams(params)
    },
    pbGetStatsUpdated: async function(params = {}) {
      params.table = 'prebid-db-bidders-stats-updated'
      return await this.pbPrepareParams(params)
    },
    pbGetOptions: async function(option = '') {
      let params = {}
      if (option != '') {
        params = {
          select: ['value'],
          operation: '',
          data: [{
            operation: '',
            data: [{
              key: 'name',
              val: option,
              con: '='
            }]
          }]
        }
      }
      params.table = 'prebid-db-options'
      return await this.pbPrepareParams(params)
    },
    pbGetNotifications: async function(option = '') {
      let params = {}
      if (option != '') {
        params = {
          select: ['value', 'notificationdate', 'status', 'name'],
          operation: '',
          data: [{
            operation: '',
            data: [{
              key: 'status',
              val: option,
              con: '='
            }]
          }]
        }
      }
      params.table = 'prebid-db-notifications'
      return await this.pbPrepareParams(params)
    }
  }
})
app.use(InvoiceDownloadPrint)
app.mount('#app')
