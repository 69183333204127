import { createRouter, createWebHistory } from 'vue-router'
// import SideMenu from '../layouts/side-menu/Main.vue'
// import SimpleMenu from '../layouts/simple-menu/Main.vue'
// import TopMenu from '../layouts/top-menu/Main.vue'
// import Home from '../views/home/Main.vue'
// import Users from '../views/users/Main.vue'
// import Bidders from '../views/bidders/Main.vue'
// import Sites from '../views/sites/Main.vue'
// import Accounting from '../views/accounting/Main.vue'
// import Options from '../views/options/Main.vue'
// import Notifications from '../views/notifications/Main.vue'
// import Inbox from '../views/inbox/Main.vue'
// import Login from '../views/login/Main.vue'
// import Register from '../views/register/Main.vue'
// import PasswordReset from '../views/password-reset/Main.vue'
// import ErrorPage from '../views/error-page/Main.vue'
// import UpdateProfile from '../views/profile/Main.vue'
// import Payments from '../views/payments/Main.vue'
// import Billing from '../views/billing/Main.vue'
// import ChangePassword from '../views/change-password/Main.vue'
// import RegularTable from '../views/regular-table/Main.vue'
// import { TabulatorFull as Tabulator } from '../views/tabulator/Main.vue'
// import Stats from '../views/stats/Main.vue'
// import BidderStatus from '../views/bidder-status/Main.vue'
// import Status from '../views/status/Main.vue'
// import AdsTxt from '../views/adstxt/Main.vue'
// import Accordion from '../views/accordion/Main.vue'
// import Button from '../views/button/Main.vue'
// import Modal from '../views/modal/Main.vue'
// import SlideOver from '../views/slide-over/Main.vue'
// import Notification from '../views/notification/Main.vue'
// import Alert from '../views/alert/Main.vue'
// import ProgressBar from '../views/progress-bar/Main.vue'
// import Tooltip from '../views/tooltip/Main.vue'
// import Dropdown from '../views/dropdown/Main.vue'
// import Typography from '../views/typography/Main.vue'
// import Icon from '../views/icon/Main.vue'
// import LoadingIcon from '../views/loading-icon/Main.vue'
// import RegularForm from '../views/regular-form/Main.vue'
// import Datepicker from '../views/datepicker/Main.vue'
// import TomSelect from '../views/tom-select/Main.vue'
// import FileUpload from '../views/file-upload/Main.vue'
// import WysiwygEditor from '../views/wysiwyg-editor/Main.vue'
// import Validation from '../views/validation/Main.vue'
// import Chart from '../views/chart/Main.vue'
// import Slider from '../views/slider/Main.vue'
// import ImageZoom from '../views/image-zoom/Main.vue'
// import PrivacyPolicy from '../views/privacy-policy/Main.vue'
// import TermsConditions from '../views/terms-conditions/Main.vue'
// import OptOut from '../views/opt-out/Main.vue'

import store from '../store'
// import { Auth } from 'aws-amplify'
import Auth from '@aws-amplify/auth'

const routes = [
  {
    path: '/',
    component: () =>
      import(/* webpackChunkName: 'SideMenu' */ /* webpackMode: "lazy" */'../layouts/side-menu/Main.vue'),
    children: [
      {
        path: '/',
        name: 'home',
        component: () =>
          import(/* webpackChunkName: 'Home' */ /* webpackMode: "lazy" */'../views/home/Main.vue')
      },
      // {
      //   path: 'inbox',
      //   name: 'side-menu-inbox',
      //   component: Inbox
      // },
      // {
      //   path: 'file-manager',
      //   name: 'side-menu-file-manager',
      //   component: FileManager
      // },
      // {
      //   path: 'point-of-sale',
      //   name: 'side-menu-point-of-sale',
      //   component: PointOfSale
      // },
      // {
      //   path: 'chat',
      //   name: 'side-menu-chat',
      //   component: Chat
      // },
      // {
      //   path: 'post',
      //   name: 'side-menu-post',
      //   component: Post
      // },
      // {
      //   path: 'calendar',
      //   name: 'side-menu-calendar',
      //   component: Calendar
      // },
      // {
      //   path: 'crud-data-list',
      //   name: 'side-menu-crud-data-list',
      //   component: CrudDataList
      // },
      // {
      //   path: 'crud-form',
      //   name: 'side-menu-crud-form',
      //   component: CrudForm
      // },
      // {
      //   path: 'users-layout-1',
      //   name: 'side-menu-users-layout-1',
      //   component: UsersLayout1
      // },
      // {
      //   path: 'users-layout-2',
      //   name: 'side-menu-users-layout-2',
      //   component: UsersLayout2
      // },
      // {
      //   path: 'users-layout-3',
      //   name: 'side-menu-users-layout-3',
      //   component: UsersLayout3
      // },
      // {
      //   path: 'profile-overview-1',
      //   name: 'side-menu-profile-overview-1',
      //   component: ProfileOverview1
      // },
      // {
      //   path: 'profile-overview-2',
      //   name: 'side-menu-profile-overview-2',
      //   component: ProfileOverview2
      // },
      // {
      //   path: 'profile-overview-3',
      //   name: 'side-menu-profile-overview-3',
      //   component: ProfileOverview3
      // },
      // {
      //   path: 'wizard-layout-1',
      //   name: 'side-menu-wizard-layout-1',
      //   component: WizardLayout1
      // },
      // {
      //   path: 'wizard-layout-2',
      //   name: 'side-menu-wizard-layout-2',
      //   component: WizardLayout2
      // },
      // {
      //   path: 'wizard-layout-3',
      //   name: 'side-menu-wizard-layout-3',
      //   component: WizardLayout3
      // },
      // {
      //   path: 'blog-layout-1',
      //   name: 'side-menu-blog-layout-1',
      //   component: BlogLayout1
      // },
      // {
      //   path: 'blog-layout-2',
      //   name: 'side-menu-blog-layout-2',
      //   component: BlogLayout2
      // },
      // {
      //   path: 'blog-layout-3',
      //   name: 'side-menu-blog-layout-3',
      //   component: BlogLayout3
      // },
      // {
      //   path: 'pricing-layout-1',
      //   name: 'side-menu-pricing-layout-1',
      //   component: PricingLayout1
      // },
      // {
      //   path: 'pricing-layout-2',
      //   name: 'side-menu-pricing-layout-2',
      //   component: PricingLayout2
      // },
      // {
      //   path: 'invoice-layout-1',
      //   name: 'side-menu-invoice-layout-1',
      //   component: InvoiceLayout1
      // },
      // {
      //   path: 'invoice-layout-2',
      //   name: 'side-menu-invoice-layout-2',
      //   component: InvoiceLayout2
      // },
      // {
      //   path: 'faq-layout-1',
      //   name: 'side-menu-faq-layout-1',
      //   component: FaqLayout1
      // },
      // {
      //   path: 'faq-layout-2',
      //   name: 'side-menu-faq-layout-2',
      //   component: FaqLayout2
      // },
      // {
      //   path: 'faq-layout-3',
      //   name: 'side-menu-faq-layout-3',
      //   component: FaqLayout3
      // },
      {
        path: 'profile',
        name: 'side-menu-profile',
        component: () =>
          import(/* webpackChunkName: 'profile' */ /* webpackMode: "lazy" */'../views/profile/Main.vue')
      },
      {
        path: 'billing',
        name: 'side-menu-billing',
        component: () =>
          import(/* webpackChunkName: 'Bill' */ /* webpackMode: "lazy" */ '../views/billing/Main.vue')
      },
      {
        path: 'payments',
        name: 'side-menu-payments',
        component: () =>
          import(/* webpackChunkName: 'Pay' */ /* webpackMode: "lazy" */'../views/payments/Main.vue')
      },
      {
        path: 'payments/:invoice',
        name: 'showInvoice',
        component: () =>
          import(/* webpackChunkName: 'Pay' */ /* webpackMode: "lazy" */'../views/payments/Main.vue')
      },
      // {
      //   path: 'change-password',
      //   name: 'side-menu-change-password',
      //   component: () =>
      //     import(/* webpackChunkName: 'Utils' */ /* webpackMode: "lazy" */'../views/change-password/Main.vue')
      // },
      {
        path: 'stats/video',
        name: 'videoStats',
        component: () =>
          import(/* webpackChunkName: 'Stats' */ /* webpackMode: "lazy" */'../views/stats/Main.vue')
      },
      {
        path: 'stats/display',
        name: 'displayStats',
        component: () =>
          import(/* webpackChunkName: 'Stats' */ /* webpackMode: "lazy" */'../views/stats/Main.vue')
      },
      {
        path: 'bidderstatus',
        name: 'side-menu-bidder-status',
        component: () =>
          import(/* webpackChunkName: 'bidstatus' */ /* webpackMode: "lazy" */'../views/bidder-status/Main.vue')
      },
      {
        path: 'status',
        name: 'side-menu-status',
        component: () =>
          import(/* webpackChunkName: 'sysstatus' */ /* webpackMode: "lazy" */'../views/status/Main.vue')
      },
      {
        path: 'help',
        name: 'side-menu-help',
        component: () =>
          import(/* webpackChunkName: 'sysstatus' */ /* webpackMode: "lazy" */'../views/help/Main.vue')
      },
      {
        path: 'adstxt',
        name: 'side-menu-adstxt',
        component: () =>
          import(/* webpackChunkName: 'adstxt' */ /* webpackMode: "lazy" */'../views/adstxt/Main.vue')
      },
      // {
      //   path: 'accordion',
      //   name: 'side-menu-accordion',
      //   component: Accordion
      // },
      // {
      //   path: 'button',
      //   name: 'side-menu-button',
      //   component: Button
      // },
      // {
      //   path: 'modal',
      //   name: 'side-menu-modal',
      //   component: Modal
      // },
      // {
      //   path: 'slide-over',
      //   name: 'side-menu-slide-over',
      //   component: SlideOver
      // },
      // {
      //   path: 'notification',
      //   name: 'side-menu-notification',
      //   component: Notification
      // },
      // {
      //   path: 'alert',
      //   name: 'side-menu-alert',
      //   component: Alert
      // },
      // {
      //   path: 'progress-bar',
      //   name: 'side-menu-progress-bar',
      //   component: ProgressBar
      // },
      // {
      //   path: 'tooltip',
      //   name: 'side-menu-tooltip',
      //   component: Tooltip
      // },
      // {
      //   path: 'dropdown',
      //   name: 'side-menu-dropdown',
      //   component: Dropdown
      // },
      // {
      //   path: 'typography',
      //   name: 'side-menu-typography',
      //   component: Typography
      // },
      // {
      //   path: 'icon',
      //   name: 'side-menu-icon',
      //   component: Icon
      // },
      // {
      //   path: 'loading-icon',
      //   name: 'side-menu-loading-icon',
      //   component: LoadingIcon
      // },
      // {
      //   path: 'regular-form',
      //   name: 'side-menu-regular-form',
      //   component: RegularForm
      // },
      // {
      //   path: 'datepicker',
      //   name: 'side-menu-datepicker',
      //   component: Datepicker
      // },
      // {
      //   path: 'tom-select',
      //   name: 'side-menu-tom-select',
      //   component: TomSelect
      // },
      // {
      //   path: 'file-upload',
      //   name: 'side-menu-file-upload',
      //   component: FileUpload
      // },
      // {
      //   path: 'wysiwyg-editor',
      //   name: 'side-menu-wysiwyg-editor',
      //   component: WysiwygEditor
      // },
      // {
      //   path: 'validation',
      //   name: 'side-menu-validation',
      //   component: Validation
      // },
      {
        path: 'delete',
        name: 'delete',
        component: () =>
          import('../views/delete/Main.vue')
      },
      {
        path: 'users',
        name: 'users',
        component: () =>
          import(/* webpackChunkName: 'Back' */ /* webpackMode: "lazy" */'../views/users/Main.vue')
      },
      {
        path: 'users/:email',
        name: 'editUsers',
        component: () =>
          import(/* webpackChunkName: 'Back' */ '../views/users/Main.vue')
      },
      {
        path: 'bidders',
        name: 'bidders',
        component: () =>
          import(/* webpackChunkName: 'Back' */ /* webpackMode: "lazy" */'../views/bidders/Main.vue')
      },
      {
        path: 'bidders/add',
        name: 'addBidders',
        component: () =>
          import(/* webpackChunkName: 'Back' */ /* webpackMode: "lazy" */'../views/bidders/Main.vue')
      },
      {
        path: 'bidders/:bidder',
        name: 'editBidders',
        component: () =>
          import(/* webpackChunkName: 'Back' */ '../views/bidders/Main.vue')
      },
      {
        path: 'sites',
        name: 'sites',
        component: () =>
          import(/* webpackChunkName: 'Back' */ /* webpackMode: "lazy" */'../views/sites/Main.vue')
      },
      {
        path: 'sites/add',
        name: 'addSites',
        component: () =>
          import(/* webpackChunkName: 'Back' */ /* webpackMode: "lazy" */'../views/sites/Main.vue')
      },
      {
        path: 'sites/:name',
        name: 'editSites',
        component: () =>
          import(/* webpackChunkName: 'Back' */ '../views/sites/Main.vue')
      },
      {
        path: 'accounting',
        name: 'accounting',
        component: () =>
          import(/* webpackChunkName: 'Back' */ /* webpackMode: "lazy" */'../views/accounting/Main.vue')
      },
      {
        path: 'accounting/:name',
        name: 'editAccounting',
        component: () =>
          import(/* webpackChunkName: 'Back' */ '../views/accounting/Main.vue')
      },
      {
        path: 'options',
        name: 'options',
        component: () =>
          import(/* webpackChunkName: 'Back' */ '../views/options/Main.vue')
      },
      {
        path: 'options/add',
        name: 'addOptions',
        component: () =>
          import(/* webpackChunkName: 'Back' */ '../views/options/Main.vue')
      },
      {
        path: 'options/:name',
        name: 'editOptions',
        component: () =>
          import(/* webpackChunkName: 'Back' */ '../views/options/Main.vue')
      },
      {
        path: 'notifications',
        name: 'notifications',
        component: () =>
          import(/* webpackChunkName: 'Back' */ /* webpackMode: "lazy" */'../views/notifications/Main.vue')
      },
      {
        path: 'notifications/add',
        name: 'addNotifications',
        component: () =>
          import(/* webpackChunkName: 'Back' */ /* webpackMode: "lazy" */'../views/notifications/Main.vue')
      },
      {
        path: 'notifications/:name',
        name: 'editNotifications',
        component: () =>
          import(/* webpackChunkName: 'Back' */ '../views/notifications/Main.vue')
      }
    ]
  },
  // {
  //   path: '/simple-menu',
  //   component: SimpleMenu,
  //   children: [
  //     {
  //       path: '/',
  //       name: 'simple-menu-home',
  //       component: Home
  //     },
  //     // {
  //     //   path: 'dashboard-overview-2',
  //     //   name: 'simple-menu-dashboard-overview-2',
  //     //   component: DashboardOverview2
  //     // },
  //     // {
  //     //   path: 'home',
  //     //   name: 'simple-menu-home',
  //     //   component: Home
  //     // },
  //     // {
  //     //   path: 'inbox',
  //     //   name: 'simple-menu-inbox',
  //     //   component: Inbox
  //     // },
  //     // {
  //     //   path: 'file-manager',
  //     //   name: 'simple-menu-file-manager',
  //     //   component: FileManager
  //     // },
  //     // {
  //     //   path: 'point-of-sale',
  //     //   name: 'simple-menu-point-of-sale',
  //     //   component: PointOfSale
  //     // },
  //     // {
  //     //   path: 'chat',
  //     //   name: 'simple-menu-chat',
  //     //   component: Chat
  //     // },
  //     // {
  //     //   path: 'post',
  //     //   name: 'simple-menu-post',
  //     //   component: Post
  //     // },
  //     // {
  //     //   path: 'calendar',
  //     //   name: 'simple-menu-calendar',
  //     //   component: Calendar
  //     // },
  //     // {
  //     //   path: 'crud-data-list',
  //     //   name: 'simple-menu-crud-data-list',
  //     //   component: CrudDataList
  //     // },
  //     // {
  //     //   path: 'crud-form',
  //     //   name: 'simple-menu-crud-form',
  //     //   component: CrudForm
  //     // },
  //     // {
  //     //   path: 'users-layout-1',
  //     //   name: 'simple-menu-users-layout-1',
  //     //   component: UsersLayout1
  //     // },
  //     // {
  //     //   path: 'users-layout-2',
  //     //   name: 'simple-menu-users-layout-2',
  //     //   component: UsersLayout2
  //     // },
  //     // {
  //     //   path: 'users-layout-3',
  //     //   name: 'simple-menu-users-layout-3',
  //     //   component: UsersLayout3
  //     // },
  //     // {
  //     //   path: 'profile-overview-1',
  //     //   name: 'simple-menu-profile-overview-1',
  //     //   component: ProfileOverview1
  //     // },
  //     // {
  //     //   path: 'profile-overview-2',
  //     //   name: 'simple-menu-profile-overview-2',
  //     //   component: ProfileOverview2
  //     // },
  //     // {
  //     //   path: 'profile-overview-3',
  //     //   name: 'simple-menu-profile-overview-3',
  //     //   component: ProfileOverview3
  //     // },
  //     // {
  //     //   path: 'wizard-layout-1',
  //     //   name: 'simple-menu-wizard-layout-1',
  //     //   component: WizardLayout1
  //     // },
  //     // {
  //     //   path: 'wizard-layout-2',
  //     //   name: 'simple-menu-wizard-layout-2',
  //     //   component: WizardLayout2
  //     // },
  //     // {
  //     //   path: 'wizard-layout-3',
  //     //   name: 'simple-menu-wizard-layout-3',
  //     //   component: WizardLayout3
  //     // },
  //     // {
  //     //   path: 'blog-layout-1',
  //     //   name: 'simple-menu-blog-layout-1',
  //     //   component: BlogLayout1
  //     // },
  //     // {
  //     //   path: 'blog-layout-2',
  //     //   name: 'simple-menu-blog-layout-2',
  //     //   component: BlogLayout2
  //     // },
  //     // {
  //     //   path: 'blog-layout-3',
  //     //   name: 'simple-menu-blog-layout-3',
  //     //   component: BlogLayout3
  //     // },
  //     // {
  //     //   path: 'pricing-layout-1',
  //     //   name: 'simple-menu-pricing-layout-1',
  //     //   component: PricingLayout1
  //     // },
  //     // {
  //     //   path: 'pricing-layout-2',
  //     //   name: 'simple-menu-pricing-layout-2',
  //     //   component: PricingLayout2
  //     // },
  //     // {
  //     //   path: 'invoice-layout-1',
  //     //   name: 'simple-menu-invoice-layout-1',
  //     //   component: InvoiceLayout1
  //     // },
  //     // {
  //     //   path: 'invoice-layout-2',
  //     //   name: 'simple-menu-invoice-layout-2',
  //     //   component: InvoiceLayout2
  //     // },
  //     // {
  //     //   path: 'faq-layout-1',
  //     //   name: 'simple-menu-faq-layout-1',
  //     //   component: FaqLayout1
  //     // },
  //     // {
  //     //   path: 'faq-layout-2',
  //     //   name: 'simple-menu-faq-layout-2',
  //     //   component: FaqLayout2
  //     // },
  //     // {
  //     //   path: 'faq-layout-3',
  //     //   name: 'simple-menu-faq-layout-3',
  //     //   component: FaqLayout3
  //     // },
  //     {
  //       path: 'profile',
  //       name: 'simple-menu-update-profile',
  //       component: UpdateProfile
  //     },
  //     {
  //       path: 'payments',
  //       name: 'simple-menu-payments',
  //       component: Payments
  //     },
  //     {
  //       path: 'change-password',
  //       name: 'simple-menu-change-password',
  //       component: ChangePassword
  //     },
  //     // {
  //     //   path: 'accordion',
  //     //   name: 'simple-menu-accordion',
  //     //   component: Accordion
  //     // },
  //     // {
  //     //   path: 'button',
  //     //   name: 'simple-menu-button',
  //     //   component: Button
  //     // },
  //     // {
  //     //   path: 'modal',
  //     //   name: 'simple-menu-modal',
  //     //   component: Modal
  //     // },
  //     // {
  //     //   path: 'slide-over',
  //     //   name: 'simple-menu-slide-over',
  //     //   component: SlideOver
  //     // },
  //     // {
  //     //   path: 'notification',
  //     //   name: 'simple-menu-notification',
  //     //   component: Notification
  //     // },
  //     // {
  //     //   path: 'alert',
  //     //   name: 'simple-menu-alert',
  //     //   component: Alert
  //     // },
  //     // {
  //     //   path: 'progress-bar',
  //     //   name: 'simple-menu-progress-bar',
  //     //   component: ProgressBar
  //     // },
  //     // {
  //     //   path: 'tooltip',
  //     //   name: 'simple-menu-tooltip',
  //     //   component: Tooltip
  //     // },
  //     // {
  //     //   path: 'dropdown',
  //     //   name: 'simple-menu-dropdown',
  //     //   component: Dropdown
  //     // },
  //     // {
  //     //   path: 'typography',
  //     //   name: 'simple-menu-typography',
  //     //   component: Typography
  //     // },
  //     // {
  //     //   path: 'icon',
  //     //   name: 'simple-menu-icon',
  //     //   component: Icon
  //     // },
  //     // {
  //     //   path: 'loading-icon',
  //     //   name: 'simple-menu-loading-icon',
  //     //   component: LoadingIcon
  //     // },
  //     // {
  //     //   path: 'regular-form',
  //     //   name: 'simple-menu-regular-form',
  //     //   component: RegularForm
  //     // },
  //     // {
  //     //   path: 'datepicker',
  //     //   name: 'simple-menu-datepicker',
  //     //   component: Datepicker
  //     // },
  //     // {
  //     //   path: 'tom-select',
  //     //   name: 'simple-menu-tom-select',
  //     //   component: TomSelect
  //     // },
  //     // {
  //     //   path: 'file-upload',
  //     //   name: 'simple-menu-file-upload',
  //     //   component: FileUpload
  //     // },
  //     // {
  //     //   path: 'wysiwyg-editor',
  //     //   name: 'simple-menu-wysiwyg-editor',
  //     //   component: WysiwygEditor
  //     // },
  //     // {
  //     //   path: 'validation',
  //     //   name: 'simple-menu-validation',
  //     //   component: Validation
  //     // },
  //     {
  //       path: 'chart',
  //       name: 'simple-menu-chart',
  //       component: Chart
  //     }
  //     // {
  //     //   path: 'slider',
  //     //   name: 'simple-menu-slider',
  //     //   component: Slider
  //     // },
  //     // {
  //     //   path: 'image-zoom',
  //     //   name: 'simple-menu-image-zoom',
  //     //   component: ImageZoom
  //     // }
  //   ]
  // },
  // {
  //   path: '/top-menu',
  //   component: TopMenu,
  //   children: [
  //     {
  //       path: '/',
  //       name: 'top-menu-home',
  //       component: Home
  //     },
  //     // {
  //     //   path: 'dashboard-overview-2',
  //     //   name: 'top-menu-dashboard-overview-2',
  //     //   component: DashboardOverview2
  //     // },
  //     // {
  //     //   path: 'home',
  //     //   name: 'top-menu-dashboard-overview-1',
  //     //   component: DashboardOverview1
  //     // },
  //     // {
  //     //   path: 'inbox',
  //     //   name: 'top-menu-inbox',
  //     //   component: Inbox
  //     // },
  //     // {
  //     //   path: 'file-manager',
  //     //   name: 'top-menu-file-manager',
  //     //   component: FileManager
  //     // },
  //     // {
  //     //   path: 'point-of-sale',
  //     //   name: 'top-menu-point-of-sale',
  //     //   component: PointOfSale
  //     // },
  //     // {
  //     //   path: 'chat',
  //     //   name: 'top-menu-chat',
  //     //   component: Chat
  //     // },
  //     // {
  //     //   path: 'post',
  //     //   name: 'top-menu-post',
  //     //   component: Post
  //     // },
  //     // {
  //     //   path: 'calendar',
  //     //   name: 'top-menu-calendar',
  //     //   component: Calendar
  //     // },
  //     // {
  //     //   path: 'crud-data-list',
  //     //   name: 'top-menu-crud-data-list',
  //     //   component: CrudDataList
  //     // },
  //     // {
  //     //   path: 'crud-form',
  //     //   name: 'top-menu-crud-form',
  //     //   component: CrudForm
  //     // },
  //     // {
  //     //   path: 'users-layout-1',
  //     //   name: 'top-menu-users-layout-1',
  //     //   component: UsersLayout1
  //     // },
  //     // {
  //     //   path: 'users-layout-2',
  //     //   name: 'top-menu-users-layout-2',
  //     //   component: UsersLayout2
  //     // },
  //     // {
  //     //   path: 'users-layout-3',
  //     //   name: 'top-menu-users-layout-3',
  //     //   component: UsersLayout3
  //     // },
  //     // {
  //     //   path: 'profile-overview-1',
  //     //   name: 'top-menu-profile-overview-1',
  //     //   component: ProfileOverview1
  //     // },
  //     // {
  //     //   path: 'profile-overview-2',
  //     //   name: 'top-menu-profile-overview-2',
  //     //   component: ProfileOverview2
  //     // },
  //     // {
  //     //   path: 'profile-overview-3',
  //     //   name: 'top-menu-profile-overview-3',
  //     //   component: ProfileOverview3
  //     // },
  //     // {
  //     //   path: 'wizard-layout-1',
  //     //   name: 'top-menu-wizard-layout-1',
  //     //   component: WizardLayout1
  //     // },
  //     // {
  //     //   path: 'wizard-layout-2',
  //     //   name: 'top-menu-wizard-layout-2',
  //     //   component: WizardLayout2
  //     // },
  //     // {
  //     //   path: 'wizard-layout-3',
  //     //   name: 'top-menu-wizard-layout-3',
  //     //   component: WizardLayout3
  //     // },
  //     // {
  //     //   path: 'blog-layout-1',
  //     //   name: 'top-menu-blog-layout-1',
  //     //   component: BlogLayout1
  //     // },
  //     // {
  //     //   path: 'blog-layout-2',
  //     //   name: 'top-menu-blog-layout-2',
  //     //   component: BlogLayout2
  //     // },
  //     // {
  //     //   path: 'blog-layout-3',
  //     //   name: 'top-menu-blog-layout-3',
  //     //   component: BlogLayout3
  //     // },
  //     // {
  //     //   path: 'pricing-layout-1',
  //     //   name: 'top-menu-pricing-layout-1',
  //     //   component: PricingLayout1
  //     // },
  //     // {
  //     //   path: 'pricing-layout-2',
  //     //   name: 'top-menu-pricing-layout-2',
  //     //   component: PricingLayout2
  //     // },
  //     // {
  //     //   path: 'invoice-layout-1',
  //     //   name: 'top-menu-invoice-layout-1',
  //     //   component: InvoiceLayout1
  //     // },
  //     // {
  //     //   path: 'invoice-layout-2',
  //     //   name: 'top-menu-invoice-layout-2',
  //     //   component: InvoiceLayout2
  //     // },
  //     // {
  //     //   path: 'faq-layout-1',
  //     //   name: 'top-menu-faq-layout-1',
  //     //   component: FaqLayout1
  //     // },
  //     // {
  //     //   path: 'faq-layout-2',
  //     //   name: 'top-menu-faq-layout-2',
  //     //   component: FaqLayout2
  //     // },
  //     // {
  //     //   path: 'faq-layout-3',
  //     //   name: 'top-menu-faq-layout-3',
  //     //   component: FaqLayout3
  //     // },
  //     {
  //       path: 'profile',
  //       name: 'top-menu-update-profile',
  //       component: UpdateProfile
  //     },
  //     {
  //       path: 'payments',
  //       name: 'top-menu-payments',
  //       component: Payments
  //     },
  //     {
  //       path: 'change-password',
  //       name: 'top-menu-change-password',
  //       component: ChangePassword
  //     },
  //     // {
  //     //   path: 'accordion',
  //     //   name: 'top-menu-accordion',
  //     //   component: Accordion
  //     // },
  //     // {
  //     //   path: 'button',
  //     //   name: 'top-menu-button',
  //     //   component: Button
  //     // },
  //     // {
  //     //   path: 'modal',
  //     //   name: 'top-menu-modal',
  //     //   component: Modal
  //     // },
  //     // {
  //     //   path: 'slide-over',
  //     //   name: 'top-menu-slide-over',
  //     //   component: SlideOver
  //     // },
  //     // {
  //     //   path: 'notification',
  //     //   name: 'top-menu-notification',
  //     //   component: Notification
  //     // },
  //     // {
  //     //   path: 'alert',
  //     //   name: 'top-menu-alert',
  //     //   component: Alert
  //     // },
  //     // {
  //     //   path: 'progress-bar',
  //     //   name: 'top-menu-progress-bar',
  //     //   component: ProgressBar
  //     // },
  //     // {
  //     //   path: 'tooltip',
  //     //   name: 'top-menu-tooltip',
  //     //   component: Tooltip
  //     // },
  //     // {
  //     //   path: 'dropdown',
  //     //   name: 'top-menu-dropdown',
  //     //   component: Dropdown
  //     // },
  //     // {
  //     //   path: 'typography',
  //     //   name: 'top-menu-typography',
  //     //   component: Typography
  //     // },
  //     // {
  //     //   path: 'icon',
  //     //   name: 'top-menu-icon',
  //     //   component: Icon
  //     // },
  //     // {
  //     //   path: 'loading-icon',
  //     //   name: 'top-menu-loading-icon',
  //     //   component: LoadingIcon
  //     // },
  //     // {
  //     //   path: 'regular-form',
  //     //   name: 'top-menu-regular-form',
  //     //   component: RegularForm
  //     // },
  //     // {
  //     //   path: 'datepicker',
  //     //   name: 'top-menu-datepicker',
  //     //   component: Datepicker
  //     // },
  //     // {
  //     //   path: 'tom-select',
  //     //   name: 'top-menu-tom-select',
  //     //   component: TomSelect
  //     // },
  //     // {
  //     //   path: 'file-upload',
  //     //   name: 'top-menu-file-upload',
  //     //   component: FileUpload
  //     // },
  //     // {
  //     //   path: 'wysiwyg-editor',
  //     //   name: 'top-menu-wysiwyg-editor',
  //     //   component: WysiwygEditor
  //     // },
  //     // {
  //     //   path: 'validation',
  //     //   name: 'top-menu-validation',
  //     //   component: Validation
  //     // },
  //     {
  //       path: 'chart',
  //       name: 'top-menu-chart',
  //       component: Chart
  //     }
  //     // {
  //     //   path: 'slider',
  //     //   name: 'top-menu-slider',
  //     //   component: Slider
  //     // },
  //     // {
  //     //   path: 'image-zoom',
  //     //   name: 'top-menu-image-zoom',
  //     //   component: ImageZoom
  //     // }
  //   ]
  // },
  {
    path: '/login',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: 'Logins' */ /* webpackMode: "lazy" */'../views/login/Main.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () =>
      import(/* webpackChunkName: 'Logins' */ /* webpackMode: "lazy" */'../views/register/Main.vue')
  },
  {
    path: '/error-page',
    name: 'error-page',
    component: () =>
      import(/* webpackChunkName: 'TermsPP' */ /* webpackMode: "lazy" */'../views/error-page/Main.vue')
  },
  {
    path: '/password-reset',
    name: 'password-reset',
    component: () =>
      import(/* webpackChunkName: 'Logins' */ /* webpackMode: "lazy" */'../views/password-reset/Main.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    component: () =>
      import(/* webpackChunkName: 'TermsPP' */ /* webpackMode: "lazy" */'../views/error-page/Main.vue')
  },
  {
    path: '/privacypolicy',
    name: 'privacy-policy',
    component: () =>
      import(/* webpackChunkName: 'TermsPP' */ /* webpackMode: "lazy" */'../views/privacy-policy/Main.vue')
  },
  {
    path: '/termsandconditions',
    name: 'term-conditions',
    component: () =>
      import(/* webpackChunkName: 'TermsPP' */ /* webpackMode: "lazy" */'../views/terms-conditions/Main.vue')
  },
  {
    path: '/optout',
    name: 'opt-out',
    component: () =>
      import(/* webpackChunkName: 'TermsPP' */ /* webpackMode: "lazy" */'../views/opt-out/Main.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 }
  }
})

if (!store.state.user) {
  Auth.currentAuthenticatedUser()
    .then((user) => {
      store.state.user = user.attributes
    })
    .catch(err => {
      if (err == 'The user is not authenticated') {
        store.state.user = null
      }
    })
}

router.beforeEach((to, from, next) => {
  if (to.name == 'login') {
    if (!store.state.user) {
      return next()
    } else {
      return next('/')
    }
  } else {
    if (!store.state.user) {
      if (to.name == 'password-reset' || to.name == 'register' || to.name == 'privacy-policy' || to.name == 'term-conditions' || to.name == 'opt-out') {
        return next()
      } else {
        return next('/login')
      }
    } else {
      return next()
    }
  }
})

export default router
