/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log('%cAdysis%c App is being served from cache by a service worker.', 'background: rgb(19 177 118);color:#FFF;padding:5px;border-radius: 5px;line-height: 10px;', '')
    },
    registered () {
      console.log('%cAdysis%c Service worker has been registered.', 'background: rgb(19 177 118);color:#FFF;padding:5px;border-radius: 5px;line-height: 10px;', '')
    },
    cached () {
      console.log('%cAdysis%c Content has been cached for offline use.', 'background: rgb(19 177 118);color:#FFF;padding:5px;border-radius: 5px;line-height: 10px;', '')
    },
    updatefound () {
      console.log('%cAdysis%c New content is available; please reload page.', 'background: rgb(19 177 118);color:#FFF;padding:5px;border-radius: 5px;line-height: 10px;', '')
    },
    updated () {
      console.log('%cAdysis%c New content has been downloaded', 'background: rgb(19 177 118);color:#FFF;padding:5px;border-radius: 5px;line-height: 10px;', '')
      navigator.serviceWorker.ready.then(() => {
        console.log('Refreshing in 1 second...')
        setTimeout(() => {
          window.location.reload(true)
        }, 2000)
      })
    },
    offline () {
      console.log('%cAdysis%c No internet connection found. Adysis App is running in offline mode.', 'background: rgb(19 177 118);color:#FFF;padding:5px;border-radius: 5px;line-height: 10px;', '')
    },
    error (error) {
      console.error('%cAdysis%c Error during Adysis service worker registration:', 'background: rgb(19 177 118);color:#FFF;padding:5px;border-radius: 5px;line-height: 10px;', '', error)
    }
  })
}
